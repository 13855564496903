// Constants:
const ROUTES = {
  INDEX: '/',
  HOME: '/home',
  FEED: '/feed',
  DOWNLOADS: '/downloads',
  HELP: '/help',
  LOGIN: '/login',
  REGISTER: '/register',
  WEBSITE: '/w/:websiteID',
  PROFILE: '/:username',
  _404: '/404',
  EXTERNAL: {
    WEBSTORE: 'https://chrome.google.com/webstore/detail/openreply/noljkklhldpabidacigodpiiimjgodfk'
  }
};


// Exports:
export default ROUTES;
