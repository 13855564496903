// Exports:
export const SPECIAL_ROUTES = {
  BACK: 'BACK'
};

export const performViewTransition = (
  e: any,
  setMounted: React.Dispatch<React.SetStateAction<-1 | 0 | 1>>,
  setNavigateTo: React.Dispatch<React.SetStateAction<string | null>>,
  nextRoute: string
) => {
  e.preventDefault();
  setNavigateTo(nextRoute)
  setMounted(1);
};
