// Packages:
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Color from 'color';
import { useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { useSelector } from 'react-redux';
import useTimeout from '../../hooks/use-timeout';
import { performViewTransition } from '../../util/performViewTransition';


// Typescript:
import { TTHEME } from '../../styles/ts/types';
import { IState } from '../../redux/reducers/ts/interfaces';


// Constants:
import COLORS from '../../styles/colors';
import THEMES from '../../styles/themes';
import ROUTES from '../../routes';
import { SPECIAL_ROUTES } from '../../util/performViewTransition';


// Styles:
const Wrapper = styled.div<{ theme: TTHEME, mounted: -1 | 0 | 1  }>`
  width: 100vw;
  min-height: 100vh;
  background-color: ${ props => props.theme === THEMES.LIGHT ? COLORS.WHITE : COLORS.BLACK };
  filter: ${ props => props.mounted === 0 ? 'brightness(1)' : 'brightness(0)' };
  transition: all 0.25s ease;
`;

const EarthBackground = styled.video`
  position: absolute;
  z-index: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
`;

const Section = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90vw;
  height: 92vh;
  padding: 0 5vw;
  padding-top: 8vh;
  background-color: ${ Color(COLORS.BLACK).alpha(0.6).toString() };
`;

const Header = styled(Section)``;

const Title = styled.div`
  color: ${ COLORS.WHITE };
  font-size: 5rem;
  font-weight: 700;
  text-align: center;

  @media screen and (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 3rem;
  height: 1rem;
  padding: 1.1rem 6rem;
  color: ${ COLORS.BLACK };
  font-weight: 500;
  font-size: 1.3rem;
  background-color: ${ COLORS.WHITE };
  border-radius: 0.4rem;
  box-shadow: 0 0 30px -15px ${ COLORS.BLACK };
  user-select: none;
  cursor: pointer;
  transition: all 0.25s ease;

  @media screen and (max-width: 600px) {
    max-width: 2rem;
    height: 1rem;
    padding: 0.4rem 3rem;
    font-size: 0.75rem;
    border-radius: 0.2rem;
  }

  &:hover {
    color: ${ COLORS.WHITE };
    background-color: ${ COLORS.PRIMARY };
    transition: all 0.25s ease;
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 100%;
  margin: 0 1.5rem;
  background-color: ${ COLORS.WHITE };
`;


// Functions:
const Home = () => {
  // Constants:
  const history = useHistory();
  const lastLocation = useLastLocation();

  // State:
  const [ mounted, setMounted ] = useState<-1 | 0 | 1>(-1);
  const [ navigateTo, setNavigateTo ] = useState<null | string>(null);
  const theme = useSelector((state: IState) => state.theme);

  // Effects:
  useTimeout(() => {
    if (mounted === 1 && navigateTo !== null) {
      if (navigateTo === SPECIAL_ROUTES.BACK) {
        if (lastLocation?.pathname === undefined) {
          history.push(ROUTES.INDEX);
        } else {
          history.goBack();
        }
      } else {
        history.push(navigateTo);
      }
    }
  }, 250);
  
  useEffect(() => {
    setMounted(0);
    return () => {
      setMounted(1);
    };
  }, []);

  // Return:
  return (
    <Wrapper theme={ theme } mounted={ mounted }>
      <EarthBackground src={ 'https://i.imgur.com/lelJ6TL.mp4' } autoPlay={ true } muted={ true } loop={ true }/>
      <Header>
        <Title>Democratizing the internet,<br/>one website at a time.</Title>
        <Buttons>
          <Button onClick={ (e) => performViewTransition(e, setMounted, setNavigateTo, ROUTES.DOWNLOADS) }>Downloads</Button>
          <Divider />
          <Button onClick={ (e) => performViewTransition(e, setMounted, setNavigateTo, ROUTES.HELP) }>Help</Button>
        </Buttons>
      </Header>
    </Wrapper>
  );
};


// Exports:
export default Home;
