// Packages:
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { useSelector } from 'react-redux';
import useTimeout from '../../hooks/use-timeout';
// import { performViewTransition } from '../../util/performViewTransition';
import { isMobile } from 'react-device-detect';


// Typescript:
import { TTHEME } from '../../styles/ts/types';
import { IState } from '../../redux/reducers/ts/interfaces';


// Constants:
import COLORS from '../../styles/colors';
import THEMES from '../../styles/themes';
import ROUTES from '../../routes';
import { SPECIAL_ROUTES } from '../../util/performViewTransition';
import DONATION_ADDRESS from '../../constants/donation-address';


// Components:
import CopyToClipboard from '../../components/global/CopyToClipboard';


// Styles:
const Wrapper = styled.div<{ theme: TTHEME, mounted: -1 | 0 | 1  }>`
  width: 100vw;
  min-height: 100vh;
  color: ${ props => props.theme === THEMES.LIGHT ? COLORS.BLACK : COLORS.WHITE };
  background-color: ${ props => props.theme === THEMES.LIGHT ? COLORS.WHITE : COLORS.BLACK };
  filter: ${ props => props.mounted === 0 ? 'brightness(1)' : 'brightness(0)' };
  transition: all 0.25s ease;
`;

const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90vw;
  min-height: 92vh;
  padding: 0 5vw;
  padding-top: 8vh;
`;

const DonateSection = styled(Section)``;

const Title = styled.div`
  margin-top: 1rem;
  font-size: 5rem;
  font-weight: 700;
  text-align: center;

  @media screen and (max-width: 600px) {
    font-size: 2.5rem;
  }
`;

const Subtitle = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;

  @media screen and (max-width: 600px) {
    font-size: 0.8rem;
  }
`;

const CryptoOptions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 3rem;

  @media screen and (max-width: 300px) {
    width: 80vw;
  }

  @media screen and (min-width: 600px) {
    width: 75vw;
  }

  @media screen and (min-width: 600px) {
    width: 70vw;
  }

  @media screen and (min-width: 768px) {
    width: 70vw;
  }

  @media screen and (min-width: 992px) {
    width: 50vw;
  }

  @media screen and (min-width: 1026px) {
    width: 45vw;
  }

  @media screen and (min-width: 1200px) {
    width: auto;
  }
`;


// Functions:
const Help = () => {
  // Constants:
  const history = useHistory();
  const lastLocation = useLastLocation();

  // State:
  const [ mounted, setMounted ] = useState<-1 | 0 | 1>(-1);
  const [ navigateTo ] = useState<null | string>(null); // performViewTransition(e, setMounted, setNavigateTo, ROUTES.DOWNLOADS)
  const theme = useSelector((state: IState) => state.theme);

  // Effects:
  useTimeout(() => {
    if (mounted === 1 && navigateTo !== null) {
      if (navigateTo === SPECIAL_ROUTES.BACK) {
        if (lastLocation?.pathname === undefined) {
          history.push(ROUTES.INDEX);
        } else {
          history.goBack();
        }
      } else {
        history.push(navigateTo);
      }
    }
  }, 250);
  
  useEffect(() => {
    setMounted(0);
    return () => {
      setMounted(1);
    };
  }, []);

  // Return:
  return (
    <Wrapper theme={ theme } mounted={ mounted }>
      <DonateSection>
        <Title>Help</Title>
        <Subtitle style={{ marginTop: '1rem' }}>OpenReply is an open source project with the goals of complete decentralization and non-ownership.{ isMobile && <br /> }<br />Help us democratize the internet by sending us crypto and sharing the extension with your friends.</Subtitle>
        <CryptoOptions>
          <CopyToClipboard style={{ marginTop: '1rem' }} title={ 'Monero' } icon={ 'https://cdn.iconscout.com/icon/free/png-256/monero-441944.png' } text={ DONATION_ADDRESS.XMR } />
          <CopyToClipboard style={{ marginTop: '1rem' }} title={ 'Bitcoin' } icon={ 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/BTC_Logo.svg/183px-BTC_Logo.svg.png' } text={ DONATION_ADDRESS.BTC } />
          <CopyToClipboard style={{ marginTop: '1rem' }} title={ 'Ethereum' } icon={ 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Ethereum-icon-purple.svg/1200px-Ethereum-icon-purple.svg.png' } text={ DONATION_ADDRESS.ETH } />
          <CopyToClipboard style={{ marginTop: '1rem' }} title={ 'USDC' } icon={ 'https://cryptologos.cc/logos/usd-coin-usdc-logo.png' } text={ DONATION_ADDRESS.USDC } />
          <CopyToClipboard style={{ marginTop: '1rem' }} title={ 'USDT' } icon={ 'https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/1024/Tether-USDT-icon.png' } text={ DONATION_ADDRESS.USDT } />
          <CopyToClipboard style={{ marginTop: '1rem' }} title={ 'BCH' } icon={ 'https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/1024/Bitcoin-Cash-BCH-icon.png' } text={ DONATION_ADDRESS.BCH } />
          <CopyToClipboard style={{ marginTop: '1rem' }} title={ 'Litecoin' } icon={ 'https://cryptologos.cc/logos/litecoin-ltc-logo.png' } text={ DONATION_ADDRESS.LTC } />
          <CopyToClipboard style={{ marginTop: '1rem' }} title={ 'BNB' } icon={ 'https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/1024/Binance-Coin-BNB-icon.png' } text={ DONATION_ADDRESS.BNB } />
          <CopyToClipboard style={{ marginTop: '1rem' }} title={ 'Ripple' } icon={ 'https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/1024/Ripple-XRP-icon.png' } text={ DONATION_ADDRESS.XRP } />
        </CryptoOptions>
      </DonateSection>
    </Wrapper>
  );
};


// Exports:
export default Help;
