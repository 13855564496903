// Packages:
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import reportWebVitals from './reportWebVitals';


// Imports:
import store from './redux/store';


// Constants:
import COLORS from './styles/colors';
import ROUTES from './routes';


// Components:
import Navbar from './components/global/Navbar';
import Index from './views/Index';
import Home from './views/Home';
// import Feed from './views/Feed';
import Downloads from './views/Downloads';
import Help from './views/Help';
// import Login from './views/Login';
// import Register from './views/Register';
// import _404 from './views/404';
// import Website from './views/Website';
// import Profile from './views/Profile';


// Styles:
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    overflow-x: hidden;
    background-color: ${ COLORS.WHITE };
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  ::selection {
    padding: 1em;
    background-color: ${ COLORS.HIGHLIGHT.LIGHT };
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;


// Functions:
ReactDOM.render(
  <React.StrictMode>
    <Provider store={ store }>
      <Router>
        <GlobalStyle />
        <Navbar />
        <Switch>
          <Route exact path={ ROUTES.INDEX } component={ Index } />
          <Route exact path={ ROUTES.HOME } component={ Home } />
          {/* <Route exact path={ ROUTES.FEED } component={ Feed } /> */}
          <Route exact path={ ROUTES.DOWNLOADS } component={ Downloads } />
          <Route exact path={ ROUTES.HELP } component={ Help } />
          {/* <Route path={ ROUTES.LOGIN } component={ Login } /> */}
          {/* <Route path={ ROUTES.REGISTER } component={ Register } /> */}
          {/* <Route path={ ROUTES.WEBSITE } component={ Website } /> */}
          {/* <Route path={ ROUTES.PROFILE } component={ Profile } /> */}
          {/* <Route exact path={ ROUTES._404 } component={ _404 } /> */}
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('open')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
