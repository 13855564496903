// Typescript:
import {
  IToggleTheme,
  IUpdateAuth,
  IUpdateDatabase,
} from './ts/interfaces';


// Constants:
import {
  TOGGLE_THEME,
  UPDATE_AUTH,
  UPDATE_DATABASE,
} from '../action-types';


// Exports:
export const toggleTheme: IToggleTheme = (payload) => {
  return { type: TOGGLE_THEME, payload };
};

export const updateAuth: IUpdateAuth = (payload) => {
  return { type: UPDATE_AUTH, payload };
};

export const updateDatabase: IUpdateDatabase = (payload) => {
  return { type: UPDATE_DATABASE, payload };
};
