// Packages:
import { useState } from 'react';
import styled from 'styled-components';
import Color from 'color';
import { useSelector } from 'react-redux';


// Typescript:
import { TTHEME } from '../../../../styles/ts/types';
import { IState } from '../../../../redux/reducers/ts/interfaces';


// Imports:
import { BiLinkExternal } from 'react-icons/bi';


// Constants:
import THEMES from '../../../../styles/themes';
import COLORS from '../../../../styles/colors';


// Styles:
const Wrapper = styled.a<{ theme: TTHEME }>`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  width: 13rem;
  height: 20rem;
  margin-top: 90px;
  padding: 1rem;
  color: ${ props => props.theme === THEMES.LIGHT ? COLORS.BLACK : COLORS.WHITE };
  background-color: ${ props => props.theme === THEMES.LIGHT ? COLORS.WHITE : COLORS.BLACK };
  border-radius: 1rem;
  border: 1px solid ${ props => props.theme === THEMES.LIGHT ? Color(COLORS.WHITE).desaturate(0.5).darken(0.25).toString() : Color(COLORS.WHITE).desaturate(1).darken(0.85).toString() };
  box-shadow: 0 0 90px -60px ${ props => props.theme === THEMES.LIGHT ? COLORS.BLACK : COLORS.WHITE };
  user-select: none;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    width: 10rem;
    height: 15rem;
    margin-top: 50px;
    padding: 1rem;
    box-shadow: 0 0 50px -35px ${ props => props.theme === THEMES.LIGHT ? COLORS.BLACK : COLORS.WHITE };
  }
`;

const Title = styled.div`
  font-size: 2rem;
  font-weight: 600;
  text-align: center;

  @media screen and (max-width: 600px) {
    font-size: 1.25rem;
  }
`;

const Icon = styled.img`
  width: 3rem;
  height: 3rem;
`;

const Subtitle = styled.div`
  color: ${ Color(COLORS.WHITE).desaturate(0.5).darken(0.25).toString() };
  font-size: 1rem;
  font-weight: 400;
  text-align: center;

  @media screen and (max-width: 600px) {
    font-size: 0.75rem;
  }
`;

const ExternalIcon = styled(BiLinkExternal)`
  width: 1.5rem;
  height: 1.5rem;

  @media screen and (max-width: 600px) {
    width: 1.25rem;
    height: 1.25rem;
  }
`;


// Functions:
const DownloadCard = ({ title, subtitle, icon, link }: { title: string, subtitle: string, icon: string, link?: string }) => {
  // State:
  const theme = useSelector((state: IState) => state.theme);
  const [ isExternal ] = useState(link !== undefined || link !== '');
  
  // Return:
  return (
    <Wrapper theme={ theme } target='_blank' href={ link }>
      <Title>{ title }</Title>
      <Icon src={ icon } />
      <Subtitle>{ subtitle }</Subtitle>
      {
        isExternal
        &&
        <ExternalIcon />
      }
    </Wrapper>
  );
};


// Exports:
export default DownloadCard;
