// Constants:
const COLORS = {
  PRIMARY: '#067BC2',
  SECONDARY: '#84BCDA',
  HIGHLIGHT: {
    LIGHT: '#8EE3F5',
    DARK: '#A8E0FF'
  },
  WHITE: '#F7F9F9',
  BLACK: '#080708',
  GREEN: '#4AAD52',
  // Color(COLORS.GREEN).saturate(0.5).darken(0.2).rotate(60).toString()
  RED: '#6B0F1A',
  // Color(COLORS.RED).saturate(1).lighten(2).toString()
  // DARK_GREY: Color(COLORS.BLACK).desaturate(1).lighten(1.45).toString(),
  // GREY: Color(COLORS.WHITE).desaturate(1).darken(0.85).toString(),
  // LIGHT_GREY: Color(COLORS.WHITE).desaturate(0.5).darken(0.25).toString(),
  // CAROLINE_BLUE: Color(COLORS.PRIMARY).desaturate(0.25).lighten(0.4).toString(),
  // FALL: '#C1292E',
  // RISE: '#50C5B7',
};


// Exports:
export default COLORS;