// Packages:
import styled from 'styled-components';
import Color from 'color';
import { Link } from 'react-router-dom';


// Typescript:
import { TTHEME } from '../../../../styles/ts/types';


// Imports:
import { RiMenu2Line } from 'react-icons/ri';


// Constants:
import THEMES from '../../../../styles/themes';
import COLORS from '../../../../styles/colors';


// Exports:
export const Wrapper = styled.div<{ theme: TTHEME }>`
  position: fixed;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  width: 100vw;
  height: 8vh;
  color: ${ props => props.theme === THEMES.LIGHT ? COLORS.BLACK : COLORS.WHITE };
  background-color: ${ props => props.theme === THEMES.LIGHT ? Color(COLORS.WHITE).alpha(0.75).toString() : Color(COLORS.BLACK).alpha(0.75).toString() };
  backdrop-filter: blur(1px);
  box-shadow: 0px 0px 20px -15px ${ props => props.theme === THEMES.LIGHT ? COLORS.BLACK : COLORS.WHITE };
  user-select: none;
  transition: all 0.25s ease;
`;

export const Section = styled.div<{ centerAlign?: boolean }>`
  display: flex;
  justify-content: ${ props => props.centerAlign ? 'space-evenly' : 'unset' };
  align-items: center;
  flex-direction: row;
  width: ${ 100 / 3 }vw;
  height: 8vh;
  padding: ${ props => props.centerAlign ? 'unset' : '0 1rem' };
`;

export const LeftSection = styled(Section)`
  position: fixed;
  left: 0;
`;

export const CenterSection = styled(Section)``;

export const RightSection = styled(Section)`
  position: fixed;
  right: 0;
  flex-direction: ${ props => props.centerAlign ? 'inherit' : 'row-reverse' };
`;

export const SearchURL = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 80%;
  height: 2.5rem;
  background: ${ Color(COLORS.PRIMARY).lighten(0.75).alpha(0.1).toString() };
  border-radius: 0.3rem;
`;

export const SearchIcon = styled.div<{ imageSrc: string }>`
  width: 0.8rem;
  height: 0.8rem;
  margin: 0.75rem;
  margin-left: 1rem;
  background-image: ${ props => 'url(' + props.imageSrc + ')' };
  background-size: cover;
  background-position: center;
  filter: invert(69%) sepia(7%) saturate(598%) hue-rotate(182deg) brightness(91%) contrast(90%);
`;

export const URLInput = styled.input<{ theme: TTHEME }>`
  width: 90%;
  padding: 0.5rem 0.5rem;
  background: none;
  border: none;
  outline: none;
  color: ${ props => props.theme === THEMES.DARK ? COLORS.WHITE : COLORS.BLACK };;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.25s ease;

  &::placeholder {
    color: ${ Color(COLORS.PRIMARY).desaturate(0.3).darken(0.2).alpha(0.5).toString() };
    font-weight: 600;
  }
`;

export const MenuIcon = styled(RiMenu2Line)`
  width: 1.5rem;
  height: 1.5rem;
`;

export const Logo = styled(Link)`
  font-weight: 700;
  font-size: 2rem;
  color: ${ COLORS.PRIMARY };
  cursor: pointer;
  text-decoration: none;

  @media screen and (max-width: 600px) {
    font-size: 1.25rem;
  }
`;

export const ThemeBulbWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
`;

export const Glow = styled.div<{ theme: TTHEME }>`
  width: 0px;
  height: 0px;
  box-shadow: ${ props => props.theme === THEMES.DARK ? '0px 0px 30px 10px #fdca40' : 'initial' };
  transition: all 0.25s ease;
`;

export const ThemeBulb = styled.img`
  position: absolute;
  display: inline-block;
  width: 1.5rem;
  cursor: pointer;
  transition: all 0.25s ease;
`;

export const User = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
`;

export const UserIcon = styled.div<{ imageSrc: string }>`
  width: 1.5rem;
  height: 1.5rem;
  background-image: ${ props => 'url(' + props.imageSrc + ')' };
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  cursor: pointer;  
`;