// Constants:
const DONATION_ADDRESS = {
  XMR: '83wZmJ1vBPzEaETsNDur986ASTqNeUga7TTrphMZJN6tVpju26p5DfiFvQvsEB2h2JMuz8c539k9qh2x2jB7SC5x4WwkJKX',
  BTC: 'bc1qlr686yz850jjysy237x9j26wwnzwu8dh635742',
  ETH: '0x33e4261868bDa5f76C8b1F85cC38ec9BFD2F15fE',
  USDC: '0x33e4261868bDa5f76C8b1F85cC38ec9BFD2F15fE',
  USDT: '0x33e4261868bDa5f76C8b1F85cC38ec9BFD2F15fE',
  BCH: 'qrsm2srvqufrs66v4kjy6nuaxgfyjchld5tkx7xv7w',
  LTC: 'LT3t7p7XfeGkZEWFUudNTy1DjBBevwuYDM',
  BNB: 'bnb1qq00aqwqsavu9sghketp0evl47p3mtzvapnq0c',
  XRP: 'raKD2Yn38tgFkxDNCG32qN24Ym1kSg5kuC',
};


// Exports:
export default DONATION_ADDRESS;