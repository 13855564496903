// Typescript:
import { IAction, IState } from './ts/interfaces';

// Constants:
import {
  TOGGLE_THEME
} from '../action-types';
import THEMES from '../../styles/themes';
import { DEFAULT_ALERT_FOOTER } from '../../constants';
import { DEFAULT_AUTH_USER } from '../../constants/auth';
import { DEFAULT_DATABASE_USER } from '../../constants/database';


// Redux:
const initialState : IState = {
  theme: THEMES.DARK,
  footer: DEFAULT_ALERT_FOOTER,
  auth: {
    loaded: false,
    isAuth: false,
    user: DEFAULT_AUTH_USER
  },
  database: {
    loaded: false,
    user: DEFAULT_DATABASE_USER
  }
};


// Functions:
const reducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case TOGGLE_THEME:
      return {
        ...state,
        theme: action.payload
      };
    default:
      return state;
  };
};


// Exports:
export default reducer;
