// Packages:
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useCookie } from 'react-use';
import { isBrowser } from 'react-device-detect';


// Typescript:
import { IState } from '../../../redux/reducers/ts/interfaces';
import { TTHEME } from '../../../styles/ts/types';


// Imports:
import BULB_DARK_MODE from '../../../assets/BULB_DARK_MODE.png';
import BULB_LIGHT_MODE from '../../../assets/BULB_LIGHT_MODE.png';
import { SEARCH } from '../../../assets/icons';


// Constants:
import { THEME_COOKIE } from '../../../constants/cookies';
import THEMES from '../../../styles/themes';
import ROUTES from '../../../routes';


// Redux:
import { toggleTheme } from '../../../redux/actions';


// Styles:
import {
  Wrapper,
  LeftSection,
  SearchURL,
  SearchIcon,
  URLInput,
  CenterSection,
  MenuIcon,
  Logo,
  RightSection,
  ThemeBulbWrapper,
  Glow,
  ThemeBulb
} from './styles';


// Functions:
const Navbar = () => {
  // Constants:
  const dispatch = useDispatch();

  // State:
  const { theme } = useSelector((state: IState) => state);
  const [ cookieTheme, updateCookieTheme ] = useCookie(THEME_COOKIE);

  // Effects:
  useEffect(() => {
    if (cookieTheme === THEMES.LIGHT) {
      dispatch(toggleTheme(THEMES.LIGHT));
    } else {
      dispatch(toggleTheme(THEMES.DARK));
      updateCookieTheme(THEMES.DARK);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Functions:
  const updateTheme = (oldTheme: TTHEME) => {
    if (oldTheme === THEMES.LIGHT) {
      dispatch(toggleTheme(THEMES.DARK));
      updateCookieTheme(THEMES.DARK);
    } else {
      dispatch(toggleTheme(THEMES.LIGHT));
      updateCookieTheme(THEMES.LIGHT);
    }
  };

  // const handleProfileClick = () => {
  //   if (auth.isAuth) {
  //     history.push(ROUTES.PROFILE);
  //   } else {
  //     history.push(ROUTES.LOGIN);
  //   }
  // };

  // Return:
  return (
    <Wrapper theme={ theme }>
      <LeftSection centerAlign={ (isBrowser && window.innerWidth >= 768) }>
        {
          (isBrowser && window.innerWidth >= 768)
          ?
          <SearchURL>
            <SearchIcon imageSrc={ SEARCH } />
            <URLInput type='text' placeholder='https://openreply.app' theme={ theme } />
          </SearchURL>
          :
          <MenuIcon />
        }
      </LeftSection>
      <CenterSection centerAlign={ true }>
        <Logo to={ ROUTES.INDEX }>OpenReply</Logo>
      </CenterSection>
      <RightSection centerAlign={ (isBrowser && window.innerWidth >= 768) }>
        <ThemeBulbWrapper>
          <Glow theme={ theme } />
          <ThemeBulb
            src={ theme === THEMES.LIGHT ? BULB_LIGHT_MODE : BULB_DARK_MODE }
            onClick={ () => updateTheme(theme) }
          />
        </ThemeBulbWrapper>
        {/* <User onClick={ handleProfileClick }>
          <UserIcon
            imageSrc={ auth.user.photoURL === null ? ACCOUNT : auth.user.photoURL }
          />
        </User> */}
      </RightSection>
    </Wrapper>
  );
};


// Exports:
export default Navbar;
