// Packages:
import { useSelector } from 'react-redux';


// Typescript:
import { IState } from '../../redux/reducers/ts/interfaces';


// Components:
// import Feed from '../Feed';
import Home from '../Home';


// Functions:
const Index = () => {
  // State:
  const { auth, database } = useSelector((state: IState) => state);
  
  // Return:
  return (
    <>
      {
        auth.loaded && database.loaded && auth.isAuth
        ?
        // <Feed />
        <Home />
        :
        <Home />
      }
    </>
  );
};


// Exports:
export default Index;
