// Packages:
import React, { useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import Color from 'color';
import { useSelector } from 'react-redux';
import { useCopyToClipboard } from 'react-use';
import _ from 'lodash';


// Typescript:
import { TTHEME } from '../../../styles/ts/types';
import { IState } from '../../../redux/reducers/ts/interfaces';


// Imports:
import { MdContentCopy } from 'react-icons/md';


// Constants:
import COLORS from '../../../styles/colors';
import THEMES from '../../../styles/themes';


// Styles:
const glowUp = keyframes`
  0% {
    background-color: ${ Color(COLORS.PRIMARY).lighten(0.5).toString() };
  }
  50% {
    background-color: ${ COLORS.GREEN };
  }
  100% {
    background-color: ${ Color(COLORS.PRIMARY).lighten(0.5).toString() };
  }
`;

const Wrapper = styled.div<{ theme: TTHEME, clicked: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-right: 1rem;
  margin-left: 1rem;
  padding: 0.75rem 1rem;
  font-weight: 500;
  background-color: ${ props => props.theme === THEMES.LIGHT ? Color(COLORS.WHITE).darken(0.25).toString() : COLORS.WHITE };
  border-radius: 0.3rem;
  user-select: none;
  cursor: pointer;
  animation: ${ props => props.clicked ? css`0.5s ease 0s 1 normal both running ${ glowUp }` : 'none' };
  transition: all 0.25s ease;

  &:hover {
    background-color: ${ Color(COLORS.PRIMARY).lighten(0.5).toString() };
    transition: all 0.25s ease;
  }

  @media screen and (max-width: 600px) {
    width: auto;
    min-width: 65vw;
    margin-right: 0;
    margin-left: 0;
    padding: 0.5rem 0.75rem;
    border-radius: 0.15rem;
    font-size: 0.75rem;
  }
`;

const Title = styled.div`
  margin-left: 0.75rem;
  margin-right: 0.25rem;
  color: ${ COLORS.BLACK };
  font-weight: 700;
  text-align: center;

  @media screen and (max-width: 600px) {
    margin-left: 0.5rem;
    margin-right: 0.15rem;
  }
`;

const Icon = styled.img`
  width: 1.25rem;
  height: 1.25rem;

  @media screen and (max-width: 600px) {
    width: 0.75rem;
    height: 0.75rem;
  }
`;

const Text = styled.span`
  color: ${ COLORS.BLACK };
`;

const MdContentCopyIcon = styled(MdContentCopy)`
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  color: ${ Color(COLORS.WHITE).darken(0.8).toString() };
  outline: none;

  @media screen and (max-width: 600px) {
    width: 0.75rem;
    height: 0.75rem;
  }
`;


// Functions:
const CopyToClipboard = ({ title, icon, text, style }: { title: string, icon: string, text: string, style?: React.CSSProperties }) => {
  // State:
  const theme = useSelector((state: IState) => state.theme);
  const [ , copyToClipboard ] = useCopyToClipboard();
  const [ copyButtonClicked, setCopyButtonClicked ] = useState(false);
  
  // Return:
  return (
    <Wrapper
      onClick={ () => { setCopyButtonClicked(true); copyToClipboard(text); } }
      style={ style }
      title='Copy Command to Clipboard'
      tabIndex={ 0 }
      onAnimationEnd={ () => setCopyButtonClicked(false) }
      clicked={ copyButtonClicked }
      theme={ theme }
    >
      <Icon src={ icon } />
      <Title>{ title }: </Title>
      <Text>{ _.truncate(text, { length: 20 }) }</Text>
      <MdContentCopyIcon />
    </Wrapper>
  );
};


// Exports:
export default CopyToClipboard;
