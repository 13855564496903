// Packages:
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { useSelector } from 'react-redux';
import useTimeout from '../../hooks/use-timeout';
// import { performViewTransition } from '../../util/performViewTransition';
import { isBrowser } from 'react-device-detect';


// Typescript:
import { TTHEME } from '../../styles/ts/types';
import { IState } from '../../redux/reducers/ts/interfaces';


// Constants:
import COLORS from '../../styles/colors';
import THEMES from '../../styles/themes';
import ROUTES from '../../routes';
import { SPECIAL_ROUTES } from '../../util/performViewTransition';


// Components:
import DownloadCard from '../../components/views/Downloads/DownloadCard';


// Styles:
const Wrapper = styled.div<{ theme: TTHEME, mounted: -1 | 0 | 1  }>`
  width: 100vw;
  min-height: 100vh;
  color: ${ props => props.theme === THEMES.LIGHT ? COLORS.BLACK : COLORS.WHITE };
  background-color: ${ props => props.theme === THEMES.LIGHT ? COLORS.WHITE : COLORS.BLACK };
  filter: ${ props => props.mounted === 0 ? 'brightness(1)' : 'brightness(0)' };
  transition: all 0.25s ease;
`;

const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90vw;
  height: 92vh;
  padding: 0 5vw;
  padding-top: 8vh;
`;

const DownloadsSection = styled(Section)``;

const Title = styled.div`
  font-size: 5rem;
  font-weight: 700;
  text-align: center;

  @media screen and (max-width: 600px) {
    font-size: 2.5rem;
  }
`;

const Subtitle = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;

  @media screen and (max-width: 600px) {
    font-size: 0.8rem;
  }
`;


// Functions:
const Downloads = () => {
  // Constants:
  const history = useHistory();
  const lastLocation = useLastLocation();

  // State:
  const [ mounted, setMounted ] = useState<-1 | 0 | 1>(-1);
  const [ navigateTo ] = useState<null | string>(null); // performViewTransition(e, setMounted, setNavigateTo, ROUTES.DOWNLOADS)
  const theme = useSelector((state: IState) => state.theme);

  // Effects:
  useTimeout(() => {
    if (mounted === 1 && navigateTo !== null) {
      if (navigateTo === SPECIAL_ROUTES.BACK) {
        if (lastLocation?.pathname === undefined) {
          history.push(ROUTES.INDEX);
        } else {
          history.goBack();
        }
      } else {
        history.push(navigateTo);
      }
    }
  }, 250);
  
  useEffect(() => {
    setMounted(0);
    return () => {
      setMounted(1);
    };
  }, []);

  // Return:
  return (
    <Wrapper theme={ theme } mounted={ mounted }>
      <DownloadsSection>
        <Title>Downloads</Title>
        <Subtitle style={{ marginTop: '1rem' }}>OpenReply can be downloaded on any Chromium based browser. { isBrowser && <br /> } Support for Firefox is coming up shortly.</Subtitle>
        <DownloadCard
          title={ 'Chromium' }
          subtitle={ 'Google Chrome, Brave Browser, Microsoft Edge, Opera Web' }
          icon={ 'https://upload.wikimedia.org/wikipedia/commons/f/f3/Chromium_Material_Icon.png' }
          link={ ROUTES.EXTERNAL.WEBSTORE }
        />
      </DownloadsSection>
    </Wrapper>
  );
};


// Exports:
export default Downloads;
