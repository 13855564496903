// Packages:
import { ITHEMES } from './ts/interfaces';


// Constants:
const THEMES: ITHEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
};


// Exports:
export default THEMES;